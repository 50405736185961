import { ActionTree } from 'vuex'

import { Context } from '@nuxt/types'

import { State } from './state'
import { Getters } from './getters'
import {
  SET_CLOCK,
  SET_DEVICE,
  UPDATE_NOW,
  BATTERY_COUNTDOWN,
  BATTERY_LEVEL,
  RESET_STATE
} from './mutations'
import { SET_RESYNC_THROTTLE } from './backend/mutations'
import { Getters as BackendGetters } from './backend/getters'

import { logRequest, getFingerprint } from '~/assets/helpers'

// @TODO: migrate to models
interface Device {
  name: string
  token: string
}

export interface Actions {
  nuxtClientInit: (ctx: Context) => Promise<void>
  register: (code: string) => Promise<Device | null>
  updateDevice: () => Promise<Device>
  startClock: () => void
  stopClock: () => void
}

const actions: ActionTree<State, State> = {
  async nuxtClientInit({ state, dispatch, commit, getters }, _ctx: Context) {
    await dispatch('startClock')

    const get = getters as Getters

    if (get.token) {
      this.$axios.setToken(get.token, 'Bearer')

      const user = getters['backend/user'] as BackendGetters['user']
      if (user) {
        this.$axios.setHeader('X-NS-USER-ID', `${user.id}`)
      }

      this.$axios.onRequest(config => {
        const locale = this.$i18n.locale
        config.headers.common['Accept-Language'] = locale
      })

      await dispatch('updateDevice')
      await dispatch('backend/syncSettings')

      if (state.backend && state.backend.courses.length) {
        commit(`backend/${SET_RESYNC_THROTTLE}`, false)

        await state.backend.courses.reduce(async (p, course) => {
          await p
          await dispatch('backend/syncCourse', course)
        }, Promise.resolve())

        commit(`backend/${SET_RESYNC_THROTTLE}`, true)
      }
    }
  },

  async register({ state, commit, dispatch }, name: string) {
    const info = await getFingerprint()
    const device = await logRequest(() =>
      this.$axios.$put('devices', {
        name,
        ...info
      })
    )

    if (device) {
      this.$axios.setToken(device.token, 'Bearer')
      commit(SET_DEVICE, device)

      await dispatch('backend/syncSettings')
    }

    return state.device
  },

  async updateDevice({ state, commit }) {
    const device = await logRequest(() => this.$axios.$get('devices/self'))

    if (device) {
      this.$axios.setToken(device.token, 'Bearer')
      commit(SET_DEVICE, device)
    }

    return state.device
  },

  async updateBatteryStatus({ commit }) {
    if (navigator && typeof navigator.getBattery === 'function') {
      const battery = await navigator.getBattery()

      commit(BATTERY_LEVEL, Math.floor(battery.level * 100))
    }
  },

  async reset({ commit, dispatch }) {
    commit(RESET_STATE)

    await dispatch(`backend/reset`)
  },

  startClock({ state, commit, dispatch }) {
    commit(
      SET_CLOCK,
      setInterval(() => {
        commit(UPDATE_NOW, this.$dateTime.local())
        commit(BATTERY_COUNTDOWN)

        if (!state.battery.check) {
          dispatch('updateBatteryStatus')
        }
      }, 1000)
    )
    // initially set battery-state
    dispatch('updateBatteryStatus')
  },
  stopClock({ state, commit }) {
    if (state.clock) {
      clearInterval(state.clock)
      commit(SET_CLOCK, null)
    }
  }
}

export default actions
