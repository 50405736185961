import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _045af688 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _0cc8495a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27803eff = () => interopDefault(import('../pages/license-holders/index.vue' /* webpackChunkName: "pages/license-holders/index" */))
const _7979032c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f2c3c104 = () => interopDefault(import('../pages/setup.vue' /* webpackChunkName: "pages/setup" */))
const _202afd58 = () => interopDefault(import('../pages/courses/_courseId/_dateId.vue' /* webpackChunkName: "pages/courses/_courseId/_dateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/courses",
    component: _045af688,
    name: "courses___de"
  }, {
    path: "/it",
    component: _0cc8495a,
    name: "index___it"
  }, {
    path: "/license-holders",
    component: _27803eff,
    name: "license-holders___de"
  }, {
    path: "/login",
    component: _7979032c,
    name: "login___de"
  }, {
    path: "/setup",
    component: _f2c3c104,
    name: "setup___de"
  }, {
    path: "/it/courses",
    component: _045af688,
    name: "courses___it"
  }, {
    path: "/it/license-holders",
    component: _27803eff,
    name: "license-holders___it"
  }, {
    path: "/it/login",
    component: _7979032c,
    name: "login___it"
  }, {
    path: "/it/setup",
    component: _f2c3c104,
    name: "setup___it"
  }, {
    path: "/it/courses/:courseId/:dateId?",
    component: _202afd58,
    name: "courses-courseId-dateId___it"
  }, {
    path: "/courses/:courseId/:dateId?",
    component: _202afd58,
    name: "courses-courseId-dateId___de"
  }, {
    path: "/",
    component: _0cc8495a,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
